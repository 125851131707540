/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Cracovie"), React.createElement("p", null, "Pour se loger, Cracovie s’articule en quatre quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/krakow/staremiasto.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Stare Miasto"), " : très agréable, la vieille ville médiévale regroupe l’essentiel des attractions touristiques."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/krakow/kazimierz.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kazimierz"), ", au sud : ancien quartier juif, réputé pour ses galeries d’art, ses restaurants et sa vie nocturne."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/krakow/podgorze.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Podgórze"), ", sur la rive sud de la Vistule : quartier étudiant, près de l’ancienne usine d’Oskar Schindler."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/krakow/kleparz.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kleparz"), ", au nord : plutôt tranquille, le quartier de la gare donne directement sur la vieille ville."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
